import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Spinner } from "react-bootstrap";

import { NewUser } from "../../types/form/new-user-data.type";
import useUserDetail from "../../hooks/useUserDetail";
import InvitationStatus from "./invitation-status";
import EditPermissions from "./edit-permissions";
import GenerateInvitation from "./generate-new-invitation";
import useInvitation from "../../hooks/useInvitation";
import { ExtendedUser } from "../../types/user-management";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  initialValues: Pick<NewUser, "roles" | "sports">;
}

function EditUser({
  user,
  handleClose,
}: {
  user: ExtendedUser;
  handleClose: () => void;
}) {
  if (!!user.id) {
    const { userDetailQuery, userDetailsData } = useUserDetail({
      userId: user.id,
    });

    return (
      <div className="column">
        <div className="row">
          <div className="col-6">
            <h3 className="anchor-title">User Details</h3>

            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Display name</th>
                  <td
                    className={`${
                      userDetailQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {userDetailsData?.displayName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">First name</th>
                  <td
                    className={`${
                      userDetailQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {userDetailsData?.givenName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last name</th>
                  <td
                    className={`${
                      userDetailQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {" "}
                    {userDetailsData?.surname}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>{userDetailsData?.identities[0]?.issuerAssignedId}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <h3 className="anchor-title">Job Information</h3>

            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Job title</th>
                  <td
                    className={`${
                      userDetailQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {userDetailsData?.jobTitle}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Company name</th>
                  <td
                    className={`${
                      userDetailQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {userDetailsData?.companyName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Department</th>
                  <td
                    className={`${
                      userDetailQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {userDetailsData?.department}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <h3 className="anchor-title">Invitation status</h3>

          <div className="d-block mb-4">
            <InvitationStatus user={{ ...userDetailsData, invitationId: "" }} />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <h3 className="anchor-title">Permissions</h3>
            {userDetailQuery.isLoading && (
              <Spinner animation="border" variant="secondary" role="status">
                <span className="visually-hidden">
                  <FormattedMessage id="loading" />
                </span>
              </Spinner>
            )}
            {userDetailsData && (
              <EditPermissions
                user={{ ...userDetailsData, invitationId: "" }}
                handleClose={handleClose}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    const { invitationsQuery, invitationsQueryData } = useInvitation({
      id: user.invitationId,
    });

    const invitation = invitationsQueryData[0];

    return (
      <div className="column">
        <div className="row">
          <div className="col-6">
            <h3 className="anchor-title">User Details</h3>

            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Display name</th>
                  <td
                    className={`${
                      invitationsQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {invitation?.displayName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">First name</th>
                  <td
                    className={`${
                      invitationsQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {invitation?.firstName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Last name</th>
                  <td
                    className={`${
                      invitationsQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {" "}
                    {invitation?.surname}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>{invitation?.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <h3 className="anchor-title">Job Information</h3>

            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Job title</th>
                  <td
                    className={`${
                      invitationsQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {invitation?.jobTitle}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Company name</th>
                  <td
                    className={`${
                      invitationsQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {invitation?.companyName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Department</th>
                  <td
                    className={`${
                      invitationsQuery.isLoading ? "loading-shimmer" : ""
                    }`}
                  >
                    {invitation?.department}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <h3 className="anchor-title">Invitation status</h3>

          <div className="d-block mb-4">
            <InvitationStatus user={user} />
          </div>
        </div>

        {/* If user is pending, give ability to generate a new password and copy it */}
        <GenerateInvitation user={user} />

        <div className="row">
          <div className="col-6">
            <h3 className="anchor-title">Permissions</h3>
            {invitationsQuery.isLoading && (
              <Spinner animation="border" variant="secondary" role="status">
                <span className="visually-hidden">
                  <FormattedMessage id="loading" />
                </span>
              </Spinner>
            )}

            <EditPermissions user={user} handleClose={handleClose} />
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
