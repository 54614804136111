import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import useAllGroups from "../../hooks/useAllGroups";
import getGroupDisplayName from "../../utils/getGroupDisplayName";
import useUserGroups from "../../hooks/useUserGroups";
import filterRoles from "../../utils/filterRoles";
import filterSports from "../../utils/filterSports";
import { NewUser } from "../../types/form/new-user-data.type";
import InlineFormError from "../form/InlineFormError";
import { Button, Spinner } from "react-bootstrap";
import useMutateUserGroups from "../../hooks/useMutateUserGroups";
import SwToast from "../toast/sw-toast";
import { ExtendedUser } from "../../types/user-management";
import useInvitation from "../../hooks/useInvitation";
import { useIsMutating } from "@tanstack/react-query";
import { FormattedMessage } from "gatsby-plugin-intl";

function EditPermissions({
  user,
  handleClose,
}: {
  user: ExtendedUser;
  handleClose: () => void;
}) {
  const { id, invitationId } = user;
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [disableSports, setDisableSports] = useState<boolean>(false);
  const { roles, sports } = useAllGroups();
  const roleSelectRef = useRef<any>(null);
  const sportSelectRef = useRef<any>(null);

  const isMutating = useIsMutating({
    mutationKey: ["mutate-group-member", id],
  });

  if (invitationId) {
    const { invitationsQueryData } = useInvitation({
      id: invitationId,
    });

    if (!invitationsQueryData) {
      return <></>;
    }
    const invitation = invitationsQueryData[0];

    const usersRoles = invitation.roles?.map((role: any) => {
      return {
        value: role.id,
        label: getGroupDisplayName(role.id, role.displayName),
      };
    });

    const usersSports = invitation.sports?.map((sport: any) => {
      return {
        value: sport.id,
        label: getGroupDisplayName(sport.id, sport.displayName),
      };
    });

    const allSportsOptionValue = "all-sports";

    const allSportsOption = {
      value: allSportsOptionValue,
      label: "All sports",
    };

    const sportsOptions = [allSportsOption].concat(
      sports?.map((sport) => {
        return {
          value: sport.id || "",
          label: getGroupDisplayName(sport.id, sport.displayName) || "",
        };
      })
    );

    const rolesOptions = roles?.map((role) => {
      return {
        value: role.id,
        label: getGroupDisplayName(role.id, role.displayName),
      };
    });

    const restrictedRoles = [
      process.env.GATSBY_WIPS_GROUP_ID,
      process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID,
      process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID,
    ];

    const isRestrictedRole = (roleValue: string | undefined) => {
      return restrictedRoles.some((role) => role === roleValue);
    };

    const initialDisableState = useMemo(() => {
      if (usersRoles?.length > 0) {
        return isRestrictedRole(usersRoles[0]?.value);
      }
      return false;
    }, [usersRoles]);

    const { setValue } = useForm<Pick<NewUser, "roles" | "sports">>({
      defaultValues: {
        roles: usersRoles,
        sports: initialDisableState ? [] : usersSports,
      },
    });

    useEffect(() => {
      setDisableSports(initialDisableState);

      if (initialDisableState) {
        setValue("sports", []);
      }
    }, [initialDisableState]);

    return (
      <>
        <form autoComplete="off">
          <fieldset className="mb-3">
            <div className="mb-3">
              <label htmlFor="roles" className="form-label">
                Roles (required)
              </label>
              <Select
                menuPosition="fixed"
                inputId="roles"
                placeholder={"Select role"}
                options={rolesOptions}
                defaultValue={usersRoles}
                isDisabled={true}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="sports" className="form-label block-title">
                Sports
              </label>
              <Select
                menuPosition="fixed"
                isMulti
                isDisabled={true}
                inputId="sport"
                placeholder={
                  disableSports
                    ? "This user has access to all sports."
                    : "Select sport"
                }
                options={sportsOptions ?? []}
                defaultValue={usersSports}
              />
            </div>
          </fieldset>

          <Button
            variant="primary"
            className="text-nowrap d-block mb-2"
            disabled
          >
            You are unable to change roles for pending or expired invitations.
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
        </form>
      </>
    );
  }

  if (!id) {
    return <></>;
  }

  const { userGroupsData, userGroupsQuery } = useUserGroups({ userId: id });
  const { addGroupMemberMutation, removeGroupMemberMutation } =
    useMutateUserGroups(id);
  const usersRoles = filterRoles(userGroupsData)?.map((role) => {
    return {
      value: role.id,
      label: getGroupDisplayName(role.id, role.displayName),
    };
  });

  const usersSports = filterSports(userGroupsData)?.map((sport) => {
    return {
      value: sport.id,
      label: getGroupDisplayName(sport.id, sport.displayName),
    };
  });

  const allSportsOptionValue = "all-sports";

  const allSportsOption = {
    value: allSportsOptionValue,
    label: "All sports",
  };

  const sportsOptions = [allSportsOption].concat(
    sports?.map((sport) => {
      return {
        value: sport.id || "",
        label: getGroupDisplayName(sport.id, sport.displayName) || "",
      };
    })
  );

  const rolesOptions = roles?.map((role) => {
    return {
      value: role.id,
      label: getGroupDisplayName(role.id, role.displayName),
    };
  });

  const restrictedRoles = [
    process.env.GATSBY_WIPS_GROUP_ID,
    process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID,
    process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID,
  ];

  const isRestrictedRole = (roleValue: string | undefined) => {
    return restrictedRoles.some((role) => role === roleValue);
  };

  const initialDisableState = useMemo(() => {
    if (usersRoles?.length > 0) {
      return isRestrictedRole(usersRoles[0]?.value);
    }
    return false;
  }, [usersRoles]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Pick<NewUser, "roles" | "sports">>({
    // validate on submit - button must be enabled for this to work
    mode: "onSubmit",
    // after submit, revalidate form when user completes a field
    reValidateMode: "onBlur",
    defaultValues: {
      roles: usersRoles,
      sports: initialDisableState ? [] : usersSports,
    },
  });

  const onSubmit = (data: Pick<NewUser, "roles" | "sports">) => {
    const { roles: rolesData, sports: sportsData } = data;

    // check for new roles added
    if (rolesData.length > 0) {
      if (!usersRoles.some((userRole) => userRole.value === rolesData[0])) {
        // can only have 1 role selected through the UI - users with more than 1 role must come through support/Azure portal
        const { value } = rolesData[0];
        addGroupMemberMutation.mutate(value);
      }
    }

    // check for new sports added
    if (sportsData.length > 0) {
      sportsData.forEach((sport: any) => {
        if (!usersSports.some((userSport) => userSport.value === sport.value)) {
          addGroupMemberMutation.mutate(sport.value);
        }
      });
    }
    // check for roles removed
    if (usersRoles.length > 0) {
      usersRoles.forEach((userRole: any) => {
        // but now its not included in the data submitted
        if (!rolesData.some((newRole) => newRole.value === userRole.value)) {
          removeGroupMemberMutation.mutate(userRole.value);
        }
      });
    }
    // check for sports removed
    if (usersSports.length > 0) {
      usersSports.forEach((userSport: any) => {
        // but now its not included in the data submitted
        if (!sportsData.some((newRole) => newRole.value === userSport.value)) {
          removeGroupMemberMutation.mutate(userSport.value);
        }
      });
    }

    // Show toast
    setShowSuccessToast(true);
  };

  useEffect(() => {
    setDisableSports(initialDisableState);

    if (initialDisableState) {
      setValue("sports", []);
    }
  }, [initialDisableState]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <fieldset className="mb-3">
          <div className="mb-3">
            <label htmlFor="roles" className="form-label">
              Roles (required)
            </label>
            <Controller
              control={control}
              name="roles"
              rules={{ required: true }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Select
                  key={`roles__${user.id}`}
                  ref={roleSelectRef}
                  menuPosition="fixed"
                  inputId="roles"
                  aria-invalid={error ? "true" : "false"}
                  onChange={(selectedOption) => {
                    setValue("roles", selectedOption ? [selectedOption] : []);
                    if (selectedOption && selectedOption?.value) {
                      // Check if the selected role is restricted and update the state
                      setDisableSports(isRestrictedRole(selectedOption?.value));
                      // remove sports if it is a restricted role - only sport lead can have a sport
                      if (isRestrictedRole(selectedOption?.value)) {
                        setValue("sports", []);
                        (sportSelectRef as any).current.clearValue();
                      }
                    }
                  }}
                  placeholder={"Select role"}
                  options={rolesOptions}
                  defaultValue={usersRoles}
                />
              )}
            />
            {errors.roles?.type === "required" && (
              <InlineFormError message="At least one role is required" />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="sports" className="form-label block-title">
              Sports
            </label>
            <Controller
              control={control}
              name="sports"
              rules={{ required: false }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Select
                  key={`sports__${user.id}`}
                  ref={sportSelectRef}
                  menuPosition="fixed"
                  isMulti
                  isDisabled={disableSports}
                  inputId="sport"
                  aria-invalid={error ? "true" : "false"}
                  onChange={(selectedOptions) => {
                    if (
                      selectedOptions?.find(
                        (option) => option.value === allSportsOptionValue
                      )
                    ) {
                      setValue(
                        "sports",
                        sportsOptions.map((opt) => {
                          return { value: opt.value, label: opt.label };
                        })
                      );
                    } else
                      setValue(
                        "sports",
                        selectedOptions?.map((opt) => {
                          return { value: opt.value, label: opt.label };
                        })
                      );
                  }}
                  placeholder={
                    disableSports
                      ? "This user has access to all sports."
                      : "Select sport"
                  }
                  options={sportsOptions ?? []}
                  defaultValue={usersSports}
                />
              )}
            />
          </div>
        </fieldset>

        <Button
          variant="primary"
          className="text-nowrap d-block mb-2"
          type="submit"
          disabled={!!isMutating || userGroupsQuery.isFetching}
        >
          {!!isMutating || userGroupsQuery.isFetching ? (
            <Spinner animation="border" variant="white" role="status" size="sm">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          ) : (
            "Save Changes"
          )}
        </Button>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </form>

      <SwToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        header={"Permissions updated"}
        body={"Permissions were successfully updated"}
      ></SwToast>
    </>
  );
}
export default EditPermissions;
